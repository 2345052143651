import { FormikHelpers } from 'formik';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Display components
import AddMemberToPlanForm from 'display-components/add-family-member/add-member-to-plan-form';

// Components
import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// States
import {
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationEthnicitiesListSelector,
    membershipRegistrationGendersListSelector,
    membershipRegistrationRacesListSelector
} from 'state/membership-registration/membership-registration.selectors';
import { extractIdFromGenderField } from 'state/membership/membership.helpers';
import { membershipActions } from 'state/membership/membership.reducers';
import { AddressParts } from 'state/usps/usps.reducers';

// Types
import { MembershipAddMemberResponseData, MembershipPersonalDetailsFormValues } from 'types/membership';

// Utils
import { AddressValidateResponse } from 'util/usps';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

const PersonalDetailsAdultPages = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.AddAdultDataQuery;
}): ReactElement | null => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyAddress } = useAddressVerification();

    const gendersList = useSelector(membershipRegistrationGendersListSelector);
    const racesList = useSelector(membershipRegistrationRacesListSelector);
    const ethnicitiesList = useSelector(membershipRegistrationEthnicitiesListSelector);

    let defaultValues: MembershipPersonalDetailsFormValues = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        ePostPatientNumber: '',
        dobMonth: '',
        dobDay: '',
        dobYear: '',
        prescriptionNumber: '',
        isParentOrLegalGuardian: false,
        phoneNumber: '',
        age: 0,
        accountType: 'Adult',
        gender: '',
        race: '',
        ethnicity: '',
        email: '',
        username: '',
        addressTypeDesc: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        setAsDefault: false,
        addressSeqNum: '',
        careProvider: '',
        password: '',
        passwordConfirm: ''
    };

    /**
     * DRX-2397: As this values are used on Add Member flow, is
     * mandatory to have those values loaded before the user get
     * into the add member flow.
     */
    useEffect(() => {
        if ([gendersList, racesList, ethnicitiesList].some((list) => list.length === 0)) {
            dispatch(membershipRegistrationGetGendersRoutine.trigger());
            dispatch(membershipRegistrationGetRacesRoutine.trigger());
            dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleOnCancel = () => {
        navigate('/secure/profile/membership');
    };

    const { membershipID } = useSelector(accountProfileMembershipSelector);
    const ownerEpostPatientNum = useSelector(accountProfilEPostPatientNumSelector);

    const verifyMemberAddress = (values: MembershipPersonalDetailsFormValues) => {
        defaultValues = values;
        const address: AddressParts = {
            city: values.city as string,
            state: values.state as string,
            street1: values.address1 as string,
            street2: values.address2 as string,
            zip: values.zipcode as string
        };

        setIsBusy(true);
        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                setIsBusy(false);

                if (validationResponse && validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={onVerificationAddressChoice}
                                    onClose={onCloseValidationChoice}
                                    className="membership-form"
                                />
                            )
                        })
                    );
                }
            },
            onFailure: (validationResponse: AddressValidateResponse) => {
                const errors: { [key: string]: any } = {};
                setIsBusy(false);
                if (validationResponse.responseMessage) {
                    const errosField = validationResponse.responseField
                        ? validationResponse.responseField?.toLocaleLowerCase()
                        : 'address1';

                    errors[errosField] = validationResponse.responseMessage;
                }

                if (validationResponse && validationResponse.responseCode === 'invalid') {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'AddressVerificationError'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };
    const onVerificationAddressChoice = (address: AddressParts) => {
        if (address) {
            const valuesWithChosenAddress = {
                ...defaultValues,
                ...address
            };
            onCloseValidationChoice();
            handleOnSubmit(valuesWithChosenAddress);
        }
    };

    const onCloseValidationChoice = () => {
        dispatch(closeModal({}));
    };

    const handleOnSubmit = useCallback(
        (
            values: Partial<MembershipPersonalDetailsFormValues>,
            helpers?: FormikHelpers<Partial<MembershipPersonalDetailsFormValues>>
        ) => {
            setIsBusy(true);

            const parsedGenderValue = extractIdFromGenderField(values?.gender || '');

            const addMemberPayload = {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim(),
                dateOfBirth: values.dateOfBirth,
                genderId: parsedGenderValue,
                ethnicityId: values.ethnicity,
                raceId: values.race,
                address: {
                    address: values.address1,
                    aptUnit: values.address2 || '',
                    city: values.city,
                    state: values.state,
                    zipcode: values.zipcode
                },
                membershipId: membershipID,
                ePostPatientNumber: '',
                holderEPostPatientNumber: ownerEpostPatientNum,
                mobilePhone: values.phoneNumber,
                emailAddress: values.email,
                age: values.age
            };

            dispatch(membershipActions.isNewMember());
            dispatch(membershipActions.setMemberToInvite(addMemberPayload as MembershipAddMemberResponseData));
            setIsBusy(false);
            navigate('/secure/profile/membership/send-invite');
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, membershipID, ownerEpostPatientNum]
    );

    if (!ENABLE_MEMBERSHIP) {
        navigate('/secure/profile/family-account');
        return null;
    }

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('Add member to your plan') }}
            anonymousOnly={false}
        >
            <WorkflowLayoutFormWrapper eyebrowText={t('Add member to your plan')} title={t('Their personal details.')}>
                <AddMemberToPlanForm
                    state={location.state}
                    onCancel={handleOnCancel}
                    onSubmit={verifyMemberAddress}
                    accountType="Adult"
                    isBusy={isBusy}
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default PersonalDetailsAdultPages;

export const query = graphql`
    query AddAdultData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
